<template>
  <el-dialog
    title="拟任公示审查"
    :visible.sync="show"
    class="checkAppointment-index-check-component"
    :append-to-body="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="80%"
  >
    <div class="main">
      <div class="table">
        <el-table
          v-loading="table.loading"
          :data="table.data"
          row-key="id"
          height="100%"
          fit
          :header-cell-style="{ 'text-align': 'center', 'background-color': '#FAFAFA' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
          <el-table-column prop="nd" label="年度"></el-table-column>
          <el-table-column prop="sfzhm" label="身份证号码" width="180"></el-table-column>
          <el-table-column prop="xm" label="姓名"></el-table-column>
          <el-table-column prop="xb" label="性别" width="50"></el-table-column>
          <el-table-column prop="bmfs_a" label="报名方式"></el-table-column>
          <el-table-column prop="csrq" label="出生日期">
            <template slot-scope="scope">
              <span v-if="scope.row.csrq">{{ scope.row.csrq }}</span>
              <span v-else>{{ scope.row.sfzhm | csrq }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="lxfs" label="联系方式"></el-table-column>
          <el-table-column prop="cjzd" label="常住地址" width="300"></el-table-column>
          <el-table-column prop="sczt_a" label="审查状态">
            <template slot-scope="scope">
              <span
                v-if="scope.row.sczt === 3 || scope.row.sczt === 5"
                class="red"
              >{{ scope.row.sczt_a }}</span>
              <span
                v-if="scope.row.sczt === 2 || scope.row.sczt === 4"
                class="green"
              >{{ scope.row.sczt_a }}</span>
              <span v-if="scope.row.sczt === 1" class="yellow">{{ scope.row.sczt_a }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import Candidate from '@/api/choose/candidate';
import numberToString from '@/plugin/numberToString';
export default {
  // 人员信息组件--点击蓝色字体出现
  name: 'peoplelist',
  props: {},
  data() {
    return {
      table: {
        data: [],
        query: {},
        loading: false,
        total: 0,
      },
      title: '随机抽取',
      show: false,
    };
  },
  methods: {
    toggle(show, data) {
      if (show) {
        let { xrjh, bmfs } = data;
        this.table.query = { page: 1, size: 100, xrjh, bmfs };
        this.show = true;
        return this.getList(bmfs);
      }
    },
    indexMath(index) {
      return index + (this.table.query.page - 1) * this.table.query.size + 1;
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    getList() {
      this.table.loading = true;

      Candidate.list_show_ss(this.table.query)
        .then((res) => {
          let data = numberToString(res.items, 'bmfs', this.$store.getters.dic.bmfs.children);
          data = numberToString(res.items, 'sczt', this.$store.getters.dic.sczt.children);
          console.log(data);
          this.table.data = data;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() { },
  created() { },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.main {
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  .table {
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 50px;
    height: 100%;
    .el-table {
      height: 100%;
      border: 1px solid rgba(232, 232, 232, 1);
      .header-bgc {
        background-color: $border4;
      }
    }
  }
  .el-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}

::v-deep .el-dialog {
  margin: 5vh auto 0 auto !important;
}
::v-deep .el-dialog__body {
  height: 80vh;
}
.red {
  color: #ec808d;
}
.green {
  color: #03b915;
}
.yellow {
  color: rgba(245, 154, 35, 64);
}
</style>
